<script>
import ArgumentNewItem from "@/views/arguments/ArgumentNewItem.vue";
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {argumentStore} from "@/store/argumentStore";
export default {
  name: "EditProposalArguments",
  components: {
    ArgumentNewItem
  },
  data() {
    return {
      argItems: [],
      readyForSave: true
    }
  },
  computed: {
    ...mapState(proposalStore, [
      'proposalInfo',
      'proposalArguments'
    ]),
    allowCreateNewArg() {
      return this.proposalArguments.length < 10;
    }
  },
  methods: {
    ...mapActions(proposalStore, [
        'setProposalArguments',
        'addProposalArgument',
        'removeProposalArgument',
        'setAsModified'
    ]),
    ...mapActions(argumentStore, [
        'getArguments'
    ]),
    addNewArgument(ignoreChanges) {
      this.addProposalArgument({
        title: '',
        creatorType: 'USER',
        proposalId: this.proposalInfo.id,
        creatorId: this.proposalInfo.proposer.id,
        type: 'VIDEO',
        order: this.proposalArguments.length + 1
      })

      if (!ignoreChanges) {
        this.setAsModified('proposalArgumentsChanged');
      }
    },
    removeArg(argIndex) {
      this.removeProposalArgument(argIndex);
      this.setAsModified('proposalArgumentsChanged');
    },
    updateArgData(argIndex, data) {
      const activeArg = this.proposalArguments[argIndex];

      activeArg.title = data.title;
      activeArg.type = data.type;
      activeArg.order = data.order;
      if (activeArg.type === 'VIDEO') {
        // activeArg.videoGuid = data.videoGuid.length > 0 ? data.videoGuid : null;
        // activeArg.previewGuid = data.previewGuid;
      } else if (activeArg.type === 'TEXT') {
        activeArg.argText = data.argText ? data.argText : '';
      }
      this.setAsModified('proposalArgumentsChanged');
    },
    validateArguments() {
      const $arguments = this.$refs.argumentNewItem;

      this.readyForSave = true;
      if ($arguments.length > 0) {
        $arguments.forEach((arg) => {
          arg.validateArg();
          if (arg.v$.$invalid) {
            this.readyForSave = false;
          }
        })
      }
    }
  },
  mounted() {
    this.getArguments(this.proposalInfo.id).then((resp)=> {
      this.setProposalArguments(resp.data.content);

      if (this.proposalArguments.length === 0) {
        this.addNewArgument(true);
      }
    })
  }
}
</script>

<template>
  <div class="edit-prop-section arguments-section">
    <span class="edit-prop-section__title arguments-section__title">
      2. Your arguments
    </span>
    <div class="arguments-list">
      <argument-new-item v-for="(arg, index) in proposalArguments"
                         :key="index"
                         :arg-index="index"
                         :arg-data="arg"
                         :can-be-removed="proposalArguments.length > 1"
                         @on-update="updateArgData"
                         @on-remove="removeArg"
                         ref="argumentNewItem"
      />
    </div>
    <div v-if="allowCreateNewArg" class="add-arg-control" @click="addNewArgument">
      <font-awesome-icon :icon="['fas', 'circle-plus']" class="add-arg-control__icon" />
      <span class="add-arg-control__text">Add next argument</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.arguments-section {
  display: flex;
  flex-direction: column;
  min-height: 320px;

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: #4f4f4f;
    padding: 15px 0;
    border-bottom: solid 1px rgba(79, 79, 79, 0.15);
    margin: 0 0 10px;
  }

  .add-arg-control {
    cursor: pointer;
    &__icon {
      margin-right: 5px;
      font-size: 14px;
      color: #7835a6;
    }
    &__text {
      font-size: 14px;
      font-weight: 600;
      color: #7835a6;
    }
  }
}
</style>