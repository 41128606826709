import {formatZonedDateTime} from "@/baseUtils";

export class UserTaskMaintenanceType {
    static RESOLVE_CONTENT_REVIEW = "RESOLVE_CONTENT_REVIEW";
}

export class UserTaskMaintenance {

    constructor(data) {
        this.type = data.type;
        this.locked = data.locked;
        this.sinceDateTime = formatZonedDateTime(data.sinceDateTime);
        this.approximateUntilDateTime = formatZonedDateTime(data.approximateUntilDateTime);
    }
}