<script>
import {mapActions, mapState} from "pinia";
import {documentStore} from "@/store/documentStore";
import {argumentStore} from "@/store/argumentStore";
import Slider from "primevue/slider";
import ProposalArgumentInfo from "@/views/proposal/proposal-argument/ProposalArgumentInfo.vue";
import ProposalArgumentPlayerPlaylist from "@/views/proposal/proposal-argument/ProposalArgumentPlayerPlaylist.vue";

export default {
  name: "ProposalArgumentTextPlayer",
  props: {
    content: {
      type: Object,
      attachments: Array
    }
  },
  components: {
    Slider,
    ProposalArgumentInfo,
    ProposalArgumentPlayerPlaylist
  },
  data() {
    return {
      argumentText: '',
      sliderVal: 10,

      playState: false,
      timer: null,
      currentTime: 0,
      argumentAudio: null,
      showEndOverlay: false,
    }
  },
  computed: {
    ...mapState(argumentStore, [
      'isFirstArgument',
      'isLastArgument'
    ]),
    audioAttachment() {
      if (this.content) {
        return this.content.attachments.find(a => a.name === 'AUDIO');
      }
      return null;
    },
    audioLength() {
      // return this.argumentData.audioLength || 60; todo set audio
      return 60;
    },
    currentTimeInSec() {
      return this.currentTime / 1000;
    },
    currentTimeForView() {
      const pad = function (num, size) {
        return (`000${num}`).slice(size * -1);
      };
      const time = parseFloat(this.currentTimeInSec).toFixed(3);
      const minutes = Math.floor(time / 60) % 60;
      const seconds = Math.floor(time - minutes * 60);

      return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
    },
    fullTimeMilliseconds() {
      return this.audioLength * 1000;
    },
    fullTimeForView() {
      const pad = function (num, size) {
        return (`000${num}`).slice(size * -1);
      };
      const time = parseFloat(this.audioLength).toFixed(3);
      const minutes = Math.floor(time / 60) % 60;
      const seconds = Math.floor(time - minutes * 60);

      return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
    },
  },
  watch: {
    currentTime(time) {
      if (time > this.fullTimeMilliseconds) {
        this.resetTimer();
        this.showEndOverlay = true;
      }
    }
  },
  methods: {
    ...mapActions(documentStore, [
      'getDocumentContent',
      'getTextDocumentContent'
    ]),
    ...mapActions(argumentStore, [
      'prevArgumentAction',
      'nextArgumentAction'
    ]),
    getArgumentText() {
      this.getTextDocumentContent(this.content.contentId).then((documentText)=> {
        this.argumentText = documentText.data;
      });
    },
    startPlay() {
      this.playState = true;
      if (this.argumentAudio) {
        this.argumentAudio.play();
      }

      this.timer = setInterval(() => {
        this.currentTime = this.currentTime + 1000;
      }, 1000);
    },
    stopPlay() {
      this.playState = false;
      if (this.audio) {
        this.audio.pause();
      }
      clearTimeout(this.timer);
    },
    resetTimer() {
      this.stopPlay();
      this.currentTime = 0;
      this.timer = null;
      this.showEndOverlay = false;
    },
    prevArguments() {
      if (!this.isFirstArgument) {
        this.resetTimer();
        this.prevArgumentAction();
      }
    },
    nextArguments() {
      if (!this.isLastArgument) {
        this.resetTimer();
        this.nextArgumentAction()
      }
    }
  },
  beforeMount() {
    this.getArgumentText();
  },
  updated() {
    this.getArgumentText();
  }
}
</script>

<template>
<div class="arg-text-player-wrap">
  <div class="arg-text-player">
    <div class="arg-text-player__info">
      <proposal-argument-info/>
    </div>
    <div class="arg-text-player__text" v-html="argumentText"></div>

    <div class="arg-text-player__controls-bar">
      <Slider v-model="currentTime" :max="fullTimeMilliseconds" class="text-player-slider" />
      <div class="text-player-controls">
        <div class="text-player-controls__main">
          <div class="control control--play">

            <font-awesome-icon v-if="!playState"
                               :icon="['fas', 'play']"
                               class="control__icon"
                               @click="startPlay()"
            />
            <font-awesome-icon v-else
                               :icon="['fas', 'pause']"
                               class="control__icon"
                               @click="stopPlay()"
            />

          </div>
          <div class="control control--prev"
               :class="{'disabled' : isFirstArgument}"
               @click="prevArguments()"
          >
            <font-awesome-icon :icon="['fas', 'step-backward']"
                               class="control__icon"
            />
          </div>
          <div class="control control--next"
               :class="{'disabled' : isLastArgument}"
               @click="nextArguments()"
          >
            <font-awesome-icon :icon="['fas', 'step-forward']"
                               class="control__icon"
            />

          </div>
        </div>
        <div class="text-player-controls__additional">
          <span class="control control--time">{{ currentTimeForView }} / {{ fullTimeForView }}</span>
        </div>
      </div>

    </div>
  </div>

  <proposal-argument-player-playlist v-if="showEndOverlay"></proposal-argument-player-playlist>

</div>
</template>

<style lang="scss">
.arg-text-player-wrap {
  width: 637px;
  height: 357px;
  position: relative;
  opacity: .9;
  background-image: linear-gradient(119deg, #bc5bff, #8e4eba 99%);
  box-sizing: border-box;

  .arg-text-player {
    &__info {
      padding: 5px 15px 0;
      margin-bottom: 10px;
    }
    &__text {
      padding: 5px 15px 15px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      word-break: break-all;
      color: #fff;
    }
    &__controls-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 46px;
      background-color: rgba(0, 0, 0, .7);
      transition: height .2s ease-in;

      .text-player-slider {
        position: relative;
        width: 620px;
        height: 3px;
        margin: 8px auto;
        background-color: rgba(255, 255, 255, .4);
        border-radius: 4px;
        .p-slider-range {
          border-radius: 4px;
          background-color: #fff;
        }
        .p-slider-handle {
          top: 5px;
          width: 9px;
          height: 9px;
          background: #fff;
          &:before {
            width: 9px;
            height: 9px;
            background: #fff;
          }
          &:hover {
            background: transparent;
            &:before {
              background: transparent;
            }
          }
        }
      }

      .text-player-controls {
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        color: #fff;
        &__main {
          display: flex;
        }
        &__additional {

        }

        .control {
          cursor: pointer;
          &.disabled {
            opacity: 0.5;
            cursor: default;
          }
          &__icon {
            font-size: 14px;
          }
          &--play {
            min-width: 12px;
            margin-right: 15px;
          }
          &--prev {
            margin-right: 10px;
          }
          &--next {

          }
          &--time {
            font-size: 10px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>