<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {reportStore} from "@/store/reportStore";
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";


export default {
  name: "BaseUserMenu",
  components: {
    BaseAvatar,
  },
  data() {
    return {
      dropdownState: false
    };
  },
  computed: {
    ...mapState(authStore, [
      'userInfo',
      'userIsManager',
      'activeUserInfo',
      'approveOrgList',
      'approveOrgCount'
    ]),
    multipleProfiles() {
      return this.approveOrgList.length > 0;
    },
    generalUserFullName() {
      return `${this.userInfo.firstName} ${this.userInfo.lastName}`;
    },
    activeUserType() {
      let type = ''

      if(this.activeUserInfo) {
        if (this.activeUserInfo.organization) {
          type = "ORGANIZATION"
        } else {
          type = `USER`;
        }
      }

      return type;
    },
    activeUserFullName() {
      let name = ''

      if (this.activeUserInfo.organization) {
        name = this.activeUserInfo.organization.name
      } else {
        name = `${this.activeUserInfo.firstName} ${this.activeUserInfo.lastName}`;
      }

      return name;
    }
  },
  methods: {
    ...mapActions(authStore, [
      'logoutAction',
      'setActiveUserAction',
      'toggleCreateOrgModal',
      'checkUserIsApproved'
    ]),
    ...mapActions(reportStore, ['toggleReportModal']),
    showDropdown() {
      this.dropdownState = true;
    },
    hideDropdown() {
      this.dropdownState = false;
    },
    createProposal() {
      if (this.checkUserIsApproved()) {
        this.$router.push('/showcreateproposal')
      }
    },
    createAdd() {
      this.$router.push('/createad')
    },
    goToProfile() {
      let profileUrl = '';

      if (this.activeUserType === 'ORGANIZATION') {
        profileUrl = `/orgdesktop/${this.activeUserInfo.organization.id}`
      } else {
        profileUrl = `/user/${this.activeUserInfo.id}`
      }

      this.$router.push(profileUrl);
    },
    enterProfile() {
      let profileUrl = '';

      if (this.activeUserType === 'ORGANIZATION') {
        profileUrl = `/org/${this.activeUserInfo.organization.id}/editprofile`
      } else {
        profileUrl = `/member/${this.activeUserInfo.id}/editprofile`
      }

      this.$router.push(profileUrl);
    },
    registerOrganization() {
      if (this.checkUserIsApproved()) {
        this.toggleCreateOrgModal();
      }
    },
    reportContent() {
      if (this.checkUserIsApproved()) {
        this.toggleReportModal();
      }
    },
    setActiveUser(userInfo) {
      this.dropdownState = false;
      this.setActiveUserAction(userInfo);
    },
    logoutProcessing() {
      window.location.href = '/';
      this.$i18n.locale = 'se';
    },
    logout() {
      this.logoutAction().then(()=> {})
      this.logoutProcessing();
    },
    goToManagerView() {
      this.$router.push('/manager')
    }
  }
}
</script>

<template>
<div class="user-menu"
     @mouseover.stop="showDropdown"
     @mouseleave="hideDropdown"
>
  <div class="user-menu-control-item" :class="{'user-menu-control-item--active': dropdownState}">
    <div class="menu-user">
      <span class="menu-user__name">{{activeUserFullName}}</span>
      <template v-if="activeUserType === 'ORGANIZATION'">
        <base-avatar :size="'M'"
                     class="menu-user__avatar"
                     :img-hash="activeUserInfo.imageHash"
        />
      </template>
      <template v-else>
        <base-avatar :size="'M'"
                     class="menu-user__avatar"
                     :img-src="'/api/image/avatar'"
        />
      </template>
    </div>
  </div>
  <ul v-show="dropdownState" class="user-menu-list">
    <li class="user-menu-item user-menu-item--create-proposal" @click="createProposal">
      <i class="user-menu-item__icon"></i>
      <span class="user-menu-item__link">
        {{$t('header.userMenu.createProposal')}}
      </span>
    </li>
    <li class="user-menu-item user-menu-item--ad-request" @click="createAdd">
      <i class="user-menu-item__icon"></i>
      <span class="user-menu-item__link">
        {{$t('header.userMenu.createAd')}}
      </span>
    </li>
    <li class="user-menu-item user-menu-item--go-to-profile" @click="goToProfile">
      <i class="user-menu-item__icon"></i>
      <span class="user-menu-item__link">
        {{$t('header.userMenu.goToProfile')}}
      </span>
    </li>
    <temaplate v-if="userIsManager">
      <li class="user-menu-item user-menu-item--manager-view"
          @click="goToManagerView()"
      >
        <font-awesome-icon :icon="['fas', 'user-gear']"
                           class="user-menu-item__icon"
        />
          <span class="user-menu-item__link">
           Manager view
        </span>
      </li>
    </temaplate>
    <li class="user-menu-item user-menu-item--my-desktop" @click="enterProfile">
      <font-awesome-icon :icon="['fas', 'address-card']"
                         class="user-menu-item__icon"
      />
      <span class="user-menu-item__link">
        {{$t('header.userMenu.editProfile')}}
      </span>
    </li>
    <li v-if="multipleProfiles" class="user-menu-item user-menu-item--switch-profile">
      <font-awesome-icon :icon="['fas', 'users']"
                         class="user-menu-item__icon"
      />
      <i class=""></i>
      <span class="user-menu-item__link">
         {{$t('header.userMenu.switchProfile')}}
      </span>
      <ul class="user-menu-list user-menu-list--sub-menu">
        <li class="user-menu-item" @click="setActiveUser(userInfo)">
          <base-avatar :size="'M'"
                       class="user-menu-item__icon"
                       :img-src="'/api/image/avatar'"
          />
          <span class="user-menu-item__link">
            {{generalUserFullName}}
          </span>
        </li>
        <li v-for="item in approveOrgList"
            :key="item.organization.id"
            class="user-menu-item"
            @click="setActiveUser(item)"
        >
          <base-avatar :size="'M'"
                       class="user-menu-item__icon"
                       :img-hash="item.imageHash"
          />
          <span class="user-menu-item__link">
            {{item.organization.name}}
          </span>
        </li>
      </ul>
    </li>
    <li class="user-menu-item user-menu-item--reg-org" @click="registerOrganization">
      <i class="user-menu-item__icon"></i>
      <span class="user-menu-item__link">
        {{$t('header.userMenu.registerOrganization')}}
      </span>
    </li>
    <li class="user-menu-item user-menu-item--censorship" @click="reportContent">
      <i class="user-menu-item__icon"></i>
      <span class="user-menu-item__link">
        {{$t('header.userMenu.reportContent')}}
      </span>
    </li>

    <li class="user-menu-item user-menu-item--log-out" @click="logout">
      <i class="user-menu-item__icon"></i>
      <span class="user-menu-item__link">
         {{$t('header.userMenu.logout')}}
      </span>
    </li>
  </ul>
</div>
</template>

<style lang="scss">
.user-menu {
  position: relative;
  .user-menu-control-item {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 70px;
    min-width: 220px;
    padding: 0 10px;

    .menu-user {
      display: flex;
      align-items: center;
      &__avatar {
        margin-left: 10px;
      }
      &__name {
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
      }
    }
    &:hover {
      background: #672992;
      cursor: pointer;
    }

    &--active {
      background: #672992;
    }
  }

  .user-menu-list {
    position: absolute;
    z-index: 1;
    width: 220px;
    padding: 0 0 4px;
    background: #ffffff;
    color: #7835a6;
    border: solid 1px #f2f2f2;
    box-sizing: border-box;
    -webkit-box-shadow: 0 1px 8px #888888;
    -moz-box-shadow: 0 1px 8px #888888;
    box-shadow: 0 1px 8px #888888;

    &--sub-menu {
      display: none;
      flex-direction: column;
      top: 0;
      right: 100%;
      max-height: 280px;
      overflow-y: auto;
      padding-bottom: 0;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }

      .user-menu-item {
        height: 56px;
        padding: 5px 15px;

        .user-menu-item__icon {
          margin-right: 15px;
        }
      }
    }
  }

  .user-menu-item {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #f2f2f2;

      .user-menu-list--sub-menu {
        display: flex;
      }
    }

    &__icon {
      width: 45px;
      height: 45px;
      margin-right: 2px;
      background-position: center !important;
    }

    &__link {
      font-size: 16px;
      color: #4f4f4f;
    }

    &--create-proposal {
      .user-menu-item__icon {
        background: url("~@/assets/img/header/drop-down-create-proposal.svg") no-repeat;
      }
    }

    &--go-to-profile {
      .user-menu-item__icon {
        background-size: 24px 19px !important;
        background: url("~@/assets/img/header/icn_profile.svg") no-repeat;
      }
    }

    &--manager-view {
      &> .user-menu-item__icon {
        color: #BBBBBB;
        font-size: 20px;
        width: 23px;
        height: 23px;
        padding: 0 10px 0 13px;
      }
    }

    &--ad-request {
      .user-menu-item__icon {
        background: url("~@/assets/img/header/drop-down-ads.svg") no-repeat;
        background-position: 16px 15px !important;
      }
    }

    &--my-desktop {
      &> .user-menu-item__icon {
        color: #BBBBBB;
        font-size: 20px;
        width: 23px;
        height: 23px;
        padding: 0 10px 0 13px;
      }
    }
    &--switch-profile {
      background-image: url("~@/assets/img/header/keyboard_arrow_right.svg");
      background-position: right center;
      background-repeat: no-repeat;
      &> .user-menu-item__icon {
        color: #BBBBBB;
        font-size: 20px;
        width: 23px;
        height: 23px;
        padding: 0 10px 0 13px;
      }
    }
    &--reg-org {
      .user-menu-item__icon {
        background: url("~@/assets/img/header/drop-down-register-org.svg") no-repeat;
      }
    }

    &--censorship {
      .user-menu-item__icon {
        background: url("~@/assets/img/header/drop-down-censorship.svg") no-repeat;
      }
    }

    &--log-out {
      .user-menu-item__icon {
        background: url("~@/assets/img/header/drop-down-log-out.svg") no-repeat;
      }
    }
  }
}

</style>