<script>
import i18n from "@/i18n";
export default {
  name: "ProposalGeneralSubjectsList",
  props: {
    subjectsList: {
      type: Array
    }
  },
  data() {
    return {
      copiedTooltipTextState: false,
      tooltipDelay: 2000,
      tooltipHideDelay: 0
    }
  },
  computed: {
    copyTooltipText() {
      return this.copiedTooltipTextState ? i18n.global.t('copy.copied') : i18n.global.t('copy.clickToCopy');
    }
  },
  methods: {
    copySubjectText(itemText) {
      this.copiedTooltipTextState = true;
      this.tooltipHideDelay = this.tooltipDelay;

      navigator.clipboard.writeText(itemText);
      Array.from(document.querySelectorAll('.p-tooltip-text')).forEach(
          (tooltipNode) => {
            tooltipNode.textContent = this.copyTooltipText;
          }
      );

      setTimeout(()=> {
        this.copiedTooltipTextState = false;
        this.tooltipHideDelay = 0;
      }, this.tooltipDelay);
    },
    openSubject(item) {
      window.location.href = `/search/result?q=${item}&type=ALL`;
    }
  }
}
</script>

<template>
<div class="subjects-list">
  <div v-for="item in subjectsList" class="subject-item"
       :key="item"
       @click="openSubject(item)"
  >
    {{item}}
    <span class="subject-item__separator"></span>
    <div class="subject-item__btn"
         @click.stop="copySubjectText(item)"
         v-tooltip.top="{ value: copyTooltipText, hideDelay: tooltipHideDelay, class: 'subject-tooltip' }"
    >
      <font-awesome-icon :icon="['fas', 'copy']"></font-awesome-icon>
    </div>
  </div>
</div>
</template>

<style lang="scss">
.subjects-list {
  display: flex;
  padding: 10px;
  margin-top: 5px;

  .subject-item {
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 12px;
    line-height: 23px;
    border-radius: 2px;
    background: #f2f2f2;
    margin: 0 10px 5px 0;
    color: #4f4f4f;
    padding-left: 8px;
    letter-spacing: -.01px;
    cursor: pointer;

    &__separator {
      width: 1px;
      height: 16px;
      margin-left: 5px;
      background: #7B7B7B;
    }

    &__btn {
      padding: 0 8px;
    }
  }
}

.subject-tooltip {
  min-width: 130px;
  text-align: center;
}
</style>