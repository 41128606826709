<script>
import {helpers, required, url} from "@vuelidate/validators";
import i18n from "@/i18n";
import { LottieAnimation } from "lottie-web-vue"
import {useVuelidate} from "@vuelidate/core";
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {bankIdStore} from "@/store/bankIdStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import BaseHeaderMessage from "@/components/BaseHeaderMessage.vue";
import Dialog from "primevue/dialog";
import BankIdForm from "@/components/BankId/BankIdForm.vue";
import BaseMoveToControl from "@/components/BaseMoveToControl.vue";
import {maintenanceStore} from "@/store/maintenanceStore";

export default {
  name: "BecomeManagerView",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    LottieAnimation,
    BaseHeaderMessage,
    Dialog,
    BankIdForm,
    BaseMoveToControl
  },
  data() {
    return {
      showIntroState: false,
      activeFormStep: 1,

      //Animations data state
      animationData1isLoaded: false,
      animationData2isLoaded: false,
      animationData3isLoaded: false,
      animationData4isLoaded: false,

      //Form fields
      personalNumber: '',
      phoneNumber: '',
      aboutMe: '',
      applicationText: '',
      facebookProfile: '',
      instagramProfile: '',
      linkedinProfile: '',
      snapchatProfile: '',

      applicationSent: false,
      goToFormControlState: true,
      contentTopOffset: 0
    }
  },
  validations() {
    return {
      phoneNumber: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      applicationText: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required),
        htmlTags: helpers.withMessage(i18n.global.t('validations.containTags'), val => /<(.|\n)*?>/g.test(val) === false)
      },
      aboutMe: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required),
        htmlTags: helpers.withMessage(i18n.global.t('validations.containTags'), val => /<(.|\n)*?>/g.test(val) === false)
      },
      facebookProfile: {
        url: helpers.withMessage(i18n.global.t('validations.enterValidUrl'), url),
      },
      linkedinProfile: {
        url: helpers.withMessage(i18n.global.t('validations.enterValidUrl'), url),
      },
      instagramProfile: {
        url: helpers.withMessage(i18n.global.t('validations.enterValidUrl'), url),
      },
      snapchatProfile: {
        url: helpers.withMessage(i18n.global.t('validations.enterValidUrl'), url),
      },
    }
  },
  computed: {
    ...mapState(authStore, [
      'userLoggedIn',
      'userInfo',
      'userIsManager',
      'userIsActiveManager',
      'userApplications',
      'hasHeaderMessage'
    ]),
    ...mapState(bankIdStore, [
        'bankIdProfileData'
    ]),
    ...mapState(maintenanceStore, [
        'isResolveContentReviewBlocked'
    ]),
    viewHasHeaderMessage() {
      return this.hasHeaderMessage || this.isResolveContentReviewBlocked;
    },
    viewClass() {
      let viewClass = '';

      if (this.hasHeaderMessage || this.isResolveContentReviewBlocked) {
        viewClass += 'welcome-manager--has-warning '
      }

      return viewClass;
    },
    animationData_1() {
      return window.animationData_1;
    },
    animationData_2() {
      return window.animationData_2;
    },
    animationData_3() {
      return window.animationData_3;
    },
    animationData_4() {
      return window.animationData_4;
    },
    userHasNoAnsweredApplications() {
      return this.userApplications.filter((application)=> {
        return application.decision === null;
      }).length > 0;
    },
    bankIdAuthReady() {
      return this.bankIdProfileData && this.bankIdProfileData.lastLoginTime !== null;
    }
  },
  watch: {
    userLoggedIn: {
      handler() {
        setTimeout( ()=> {
          this.calculateContentOffset();
        }, 100);
      }
    },
    isResolveContentReviewBlocked: {
      handler() {
        setTimeout( ()=> {
          this.calculateContentOffset();
        }, 100);
      }
    },
    userInfo: {
      handler() {
        this.checkUserIsAuth();
      }
    },
    userIsManager: {
      handler() {
        this.checkUserIsAuth();
      }
    },
    showIntroState: {
      handler(val) {
        if (!val) {
          this.hideIntro();
        }
      }
    },
  },
  methods: {
    ...mapActions(baseLoaderStore, [
        'hideBaseLoader'
    ]),
    ...mapActions(bankIdStore, [
      'checkBankIdAuthSession',
      'getBankIdProfileData'
    ]),

    ...mapActions(authStore, [
      'logoutAction',
      'openSignUpModal',
      'authGetUserData',
      'toggleLoginModalState',
      'sendApplicationAction',
      'getUserApplications',
      'closeSignUpModal'
    ]),
    loadAnimations() {
      this.$loadScript("/html/animations/animationData_1.js")
          .then(() => {
            this.animationData1isLoaded = true;
          });

      this.$loadScript("/html/animations/animationData_2.js")
          .then(() => {
            this.animationData2isLoaded = true;
          })

      this.$loadScript("/html/animations/animationData_3.js")
          .then(() => {
            this.animationData3isLoaded = true;
          })

      this.$loadScript("/html/animations/animationData_4.js")
          .then(() => {
            this.animationData4isLoaded = true;
          })
    },
    onCloseHeaderMessage() {
      setTimeout(() => {
        this.calculateContentOffset();
      }, 50);
    },
    becomeMember() {
      this.openSignUpModal(()=> {
        this.closeSignUpModal();
        this.authGetUserData();
        this.activeFormStep = 2;
      });
    },
    showIntro: function (forceShow) {
      var hideBmIntroVideoState = sessionStorage.getItem("hideBmIntroVideo") === 'true';

      if (forceShow) {
        this.showIntroState = true;
        sessionStorage.setItem("hideBmIntroVideo", 'false');
      } else {
        this.showIntroState = !hideBmIntroVideoState;
      }
    },
    hideIntro: function () {
      this.showIntroState = false;
      sessionStorage.setItem("hideBmIntroVideo", 'true');
    },
    calculateContentOffset() {
      const $elem = document.getElementById('baseHeaderMessage');
      this.contentTopOffset =  $elem ? $elem.offsetHeight : 0;
    },
    checkUserIsAuth() {
      this.getBankIdProfileData().then(()=> {
        if (this.userLoggedIn) {
          this.getUserApplications().then(()=> {
            if (this.userHasNoAnsweredApplications) {
              this.activeFormStep = 4;
            } else {
              if (!this.userIsManager || !this.userIsActiveManager) {
                if (this.bankIdAuthReady) {
                  this.activeFormStep = 3;
                } else {
                  this.checkBankIdAuthSession(()=> {
                    if (this.userLoggedIn) {
                      this.activeFormStep = 3;
                    } else {
                      this.activeFormStep = 1;
                    }
                  }, ()=> {
                    if (this.userLoggedIn) {
                      this.activeFormStep = 2;
                    } else {
                      this.activeFormStep = 1;
                    }
                  });
                }
              } else {
                this.activeFormStep = 4;
              }
            }
            this.showIntro();
          })
        } else {
          this.activeFormStep = 1;
          this.showIntro();
        }
      })
    },
    logout() {
      this.logoutAction().then(()=> {
        this.activeFormStep = 1;
      });
    },
    async sendApplication() {
      const isFormValid = await this.v$.$validate()

      const data = {
        type: "BECOME_MANAGER",
        phoneNumber: this.phoneNumber,
        aboutMe: this.aboutMe,
        applicationText: this.applicationText,
        facebookProfile: this.facebookProfile.length > 0 ? this.facebookProfile : null,
        instagramProfile: this.instagramProfile.length > 0 ? this.instagramProfile : null,
        linkedinProfile: this.linkedinProfile.length > 0 ? this.linkedinProfile : null,
        snapchatProfile: this.snapchatProfile.length > 0 ? this.snapchatProfile : null,
      };

      this.v$.$touch();
      if (isFormValid) {
       this.sendApplicationAction(data).then(()=> {
         this.activeFormStep = 4;
       })
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  beforeMount() {
    this.loadAnimations();
  },
  mounted() {
    this.hideBaseLoader();
    this.checkUserIsAuth();
    this.calculateContentOffset();

    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.hideIntro();
      }
    });

    window.onscroll = () => {
      this.goToFormControlState = (window.innerHeight + window.scrollY) < document.body.scrollHeight - 250;
      this.calculateContentOffset();
    };

    window.onresize = () => {
      this.calculateContentOffset();
    };
  }
}
</script>

<template>
  <base-header-message v-if="viewHasHeaderMessage"
                       @on-close="onCloseHeaderMessage()"
  />
  <div class="welcome-manager"
       :class="viewClass"
       :style="{ 'top': contentTopOffset + 'px' }"
  >
    <div class="welcome-header-wrap">
      <div class="welcome-header">
        <a href="/" class="header-logo"></a>
        <ul class="header-nav">
          <li class="header-nav-item">
            <a href="/" class="header-nav-item__link">
              {{$t('becomeManager.nav.item1')}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="welcome-section welcome-section__first" id="welcome-section__first">
      <div class="welcome-section__wrap">
        <div class="welcome-section__text-wrap">
          <h1 class="title title--main" v-html="$t('becomeManager.section1.mainTitle')"></h1>
          <h2 class="title">
            {{$t('becomeManager.section1.title')}}
          </h2>
        </div>
        <LottieAnimation v-if="animationData1isLoaded"
                         :animation-data="animationData_1"
                         :auto-play="true"
                         :loop="true"
                         :speed="1"
                         class="animation"
        />
      </div>
    </div>
    <div class="welcome-section welcome-section__second" id="welcome-section__second">
      <div class="welcome-section__wrap">
        <div class="welcome-section__text-wrap">
          <h2 class="title" v-html="$t('becomeManager.section2.mainTitle')"></h2>
          <h3 class="title title--secondary">
            {{$t('becomeManager.section2.title')}}
          </h3>
        </div>
      </div>
      <LottieAnimation v-if="animationData2isLoaded"
                       :animation-data="animationData_2"
                       :auto-play="true"
                       :loop="true"
                       :speed="1"
                       class="animation"
      />
    </div>
    <div class="welcome-section welcome-section__third" id="welcome-section__third">
      <div class="welcome-section__wrap">
        <div class="welcome-section__text-wrap">
          <h2 class="title">
            {{$t('becomeManager.section3.mainTitle')}}
          </h2>
          <h3 class="title title--secondary">
            {{$t('becomeManager.section3.title')}}
          </h3>
        </div>
      </div>
      <LottieAnimation v-if="animationData3isLoaded"
                       :animation-data="animationData_3"
                       :auto-play="true"
                       :loop="true"
                       :speed="1"
                       class=""
      />
    </div>
    <div class="welcome-section welcome-section__fourth" id="welcome-section__fourth">
      <div class="welcome-section__text-wrap">
        <h2 class="title">
          {{$t('becomeManager.section4.mainTitle')}}
        </h2>
        <h3 class="title title--secondary">
          {{$t('becomeManager.section4.title')}}
        </h3>
      </div>
      <LottieAnimation v-if="animationData4isLoaded"
                       :animation-data="animationData_4"
                       :auto-play="true"
                       :loop="true"
                       :speed="1"
                       class="animation"
        />
    </div>
    <div class="welcome-footer">
      <div class="welcome-footer__text-wrap">
        <h2 class="title">
          {{$t('becomeManager.questionsSection.title')}}
        </h2>
      </div>
      <div class="welcome-footer-sections">
        <div class="welcome-footer-section welcome-footer-section--first">
          <div class="welcome-footer-section__image"></div>
          <span class="welcome-footer-section__text">
            {{$t('becomeManager.questionsSection.q1')}}
          </span>
        </div>
        <div class="welcome-footer-section welcome-footer-section--second">
          <div class="welcome-footer-section__image"></div>
          <span class="welcome-footer-section__text">
            {{$t('becomeManager.questionsSection.q2')}}
          </span>
        </div>
        <div class="welcome-footer-section welcome-footer-section--third">
          <div class="welcome-footer-section__image"></div>
          <span class="welcome-footer-section__text">
            {{$t('becomeManager.questionsSection.q3')}}
          </span>
        </div>
      </div>
    </div>

    <div class="welcome-form-section" ref="welcomeFormSection" id="welcomeFormSection">
      <h2 class="title">
        {{$t('becomeManager.form.applyNow')}}
      </h2>
      <div class="welcome-form-wrap">
        <div class="welcome-form">
          <ul class="wizard-step-list">
            <li class="wizard-step" :class="{'active': activeFormStep === 1}">
              <span class="dot"></span>
              <span class="wizard-step__label">
                <template v-if="!userLoggedIn">
                     {{$t('becomeManager.form.becomeMemberLogin')}}
                </template>
                <template v-else>
                  <span>Restart from scratch?</span>
                  <span @click="logoutAction()" class="link">Click here to log out</span>
                </template>

              </span>
            </li>
            <li class="wizard-step" :class="{'active': activeFormStep === 2}">
              <span class="dot"></span>
              <span class="wizard-step__label">
                {{$t('becomeManager.form.authenticationViaBankid')}}
              </span>
            </li>
            <li class="wizard-step last" :class="{'active': activeFormStep === 3}">
              <span class="dot"></span>
              <span class="wizard-step__label">
                {{$t('becomeManager.form.sendApplication')}}
              </span>
            </li>
          </ul>
          <template v-if="activeFormStep == 1">
            <div class="step-wrap step-wrap--1">
              <div class="step-description">
                <p class="step-description__item">
                  {{$t('becomeManager.form.youNeedToBe')}}
                </p>
                <p class="step-description__item">
                  {{$t('becomeManager.form.joinUsNow')}} <i class="smile-icon"></i>
                </p>
                <div class="login-box">
                  <button type="button" class="login-box-btn login-box-btn--become"
                          @click="becomeMember()"
                  >{{$t('becomeManager.form.becomeMember')}}</button>
                </div>
                <p class="step-description_info">
                  {{$t('becomeManager.form.alreadyMember')}}
                  <span @click="toggleLoginModalState()" class="step-description_link">
                    {{$t('becomeManager.form.here')}}
                  </span>.
                </p>
              </div>
            </div>
          </template>
          <template v-else-if="activeFormStep == 2">
            <div class="step-wrap step-wrap--2">
              <div class="form-group">
                <bank-id-form :is-become-manager-form="true" :hide-cancel-control="true"/>
              </div>
            </div>
          </template>
          <template v-else-if="activeFormStep == 3">
            <div class="step-wrap step-wrap--3">
              <h3 class="form-title">
                {{$t('becomeManager.form.basicInformation')}}
              </h3>
              <div class="form-inputs-wrap">
                <div>
                  <div class="form-group">
                    <label>
                      {{$t('becomeManager.form.firstName')}}
                    </label>
                    <input type="text"
                           v-model="userInfo.firstName"
                           :placeholder="$t('becomeManager.form.yourFirstName')"
                           class="form-input"
                           disabled="disabled"
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      {{$t('becomeManager.form.lastName')}}
                    </label>
                    <input type="text"
                           v-model="userInfo.lastName"
                           :placeholder="$t('becomeManager.form.yourLastName')"
                           class="form-input mb_40"
                           disabled="disabled"
                    />
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label>
                      {{$t('becomeManager.form.email')}}
                    </label>
                    <input type="text"
                           v-model="userInfo.email"
                           :placeholder="$t('becomeManager.form.yourEmail')"
                           disabled="disabled"
                           class="form-input"
                    />
                  </div>
                  <div class="form-group mb_65">
                    <label>
                      {{$t('becomeManager.form.phone')}}
                    </label>
                    <input v-model="phoneNumber"
                           @keypress="isNumber($event)"
                           type="text"
                           maxlength="20"
                           :placeholder="$t('becomeManager.form.pleaseEnterYour')"
                           class="form-input bm-form-field phoneNumber"
                           :class="{'form-input--error': v$.phoneNumber.$error}"
                    />
                    <template v-if="v$.phoneNumber.$error">
                      <span v-for="error of v$.phoneNumber.$errors" :key="error.$uid" class="validationMessage">
                        {{ error.$message }}
                      </span>
                    </template>
                  </div>
                </div>
              </div>
              <h3 class="form-title">
                {{$t('becomeManager.form.moreInformation')}}
              </h3>
              <span class="form-info mb_30">
                {{$t('becomeManager.form.fieldsBelowAreNotMandatory')}}
              </span>
              <div class="form-group">
                <label>
                  {{$t('becomeManager.form.whyDoYouWantThisJob')}}
                </label>
                <textarea v-model="applicationText"
                          maxlength="4000"
                          :placeholder="$t('becomeManager.form.describeWhyDoYouWantThisJob')"
                          class="form-input form-input--textarea bm-form-field"
                          :class="{'form-input--error': v$.applicationText.$error}"
                ></textarea>
                <template v-if="v$.applicationText.$error">
                    <span v-for="error of v$.applicationText.$errors" :key="error.$uid" class="validationMessage">
                      {{ error.$message }}
                  </span>
                </template>
              </div>
              <div class="form-group">
                <label>{{$t('becomeManager.form.briefDescriptionAboutYourself')}}</label>
                <textarea v-model="aboutMe"
                          maxlength="4000"
                          :placeholder="$t('becomeManager.form.briefDescriptionAboutYourself')"
                          class="form-input form-input--textarea bm-form-field"
                          :class="{'form-input--error': v$.aboutMe.$error}"
                ></textarea>
                <template v-if="v$.aboutMe.$error">
                    <span v-for="error of v$.aboutMe.$errors" :key="error.$uid" class="validationMessage">
                      {{ error.$message }}
                  </span>
                </template>
              </div>
              <div class="form-group m-0">
                <label class="mb_10">
                  Attach social media
                </label>
                <div class="form-inputs-wrap">
                  <div>
                    <div class="form-group">
                      <div class="social-link">
                        <div class="social-link__icon social-link__icon--fb"></div>
                        <input v-model="facebookProfile"
                               type="text"
                               maxlength="255"
                               placeholder='Facebook link'
                               class="bm-form-field facebookProfile"
                               :class="{'form-input--error': v$.facebookProfile.$error}"
                        >
                      </div>
                      <template v-if="v$.facebookProfile.$error">
                        <span v-for="error of v$.facebookProfile.$errors" :key="error.$uid"
                              class="validationMessage validationMessage--social-link"
                        >
                          {{ error.$message }}
                        </span>
                      </template>
                    </div>
                    <div class="form-group">
                      <div class="social-link">
                        <div class="social-link__icon social-link__icon--linkedin"></div>
                        <input v-model="linkedinProfile"
                               type="text"
                               maxlength="255"
                               placeholder='Linkedin link'
                               class="bm-form-field"
                               :class="{'form-input--error': v$.linkedinProfile.$error}"
                        />
                      </div>
                      <template v-if="v$.linkedinProfile.$error">
                        <span v-for="error of v$.linkedinProfile.$errors" :key="error.$uid"
                              class="validationMessage validationMessage--social-link"
                        >
                          {{ error.$message }}
                        </span>
                      </template>
                    </div>
                  </div>
                  <div>
                    <div class="form-group">
                      <div class="social-link">
                        <div class="social-link__icon social-link__icon--insta"></div>
                        <input v-model="instagramProfile"
                               type="text"
                               maxlength="255"
                               placeholder='Instagram link'
                               class="bm-form-field"
                               :class="{'form-input--error': v$.linkedinProfile.$error}"
                        >
                      </div>
                      <template v-if="v$.instagramProfile.$error">
                        <span v-for="error of v$.instagramProfile.$errors" :key="error.$uid"
                              class="validationMessage validationMessage--social-link"
                        >
                          {{ error.$message }}
                        </span>
                      </template>
                    </div>
                    <div class="form-group">
                      <div class="social-link">
                        <div class="social-link__icon social-link__icon--snapchat"></div>
                        <input v-model="snapchatProfile"
                               type="text"
                               maxlength="255"
                               placeholder='Snapchat link'
                               class="bm-form-field"
                               :class="{'form-input--error': v$.linkedinProfile.$error}"
                        >
                      </div>
                      <template v-if="v$.snapchatProfile.$error">
                        <span v-for="error of v$.snapchatProfile.$errors" :key="error.$uid"
                              class="validationMessage validationMessage--social-link"
                        >
                          {{ error.$message }}
                        </span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button"
                        @click="sendApplication()"
                        class="submit-button">
                  {{$t('becomeManager.form.sendApplication')}}
                </button>
              </div>
              <div class="user-terms-link">
                {{$t('becomeManager.form.byClickingSend')}}
                <a href="/userterms" target="_blank">
                  {{$t('becomeManager.form.userTerms')}}
                </a>.
              </div>
            </div>
          </template>
          <template v-else-if="activeFormStep == 4">
            <div class="step-wrap step-wrap--4">
              <template v-if="!userIsManager || !userIsActiveManager">
                <div class="final-step">
                  <div class="final-step-title">
                    <i class="fa fa-check final-step-title__icon" aria-hidden="true"></i>
                    <h3 class="final-step-title__text">
                      {{$t('becomeManager.form.thankForApplication')}}
                    </h3>
                  </div>
                  <span class="final-step__desc">
                  {{$t('becomeManager.form.weWillReviewYourApplication')}}
                </span>
                </div>
              </template>
              <template v-else>
                <div class="final-step">
                  <div class="final-step-title">
                    <i class="fa fa-check final-step-title__icon" aria-hidden="true"></i>
                    <h3 class="final-step-title__text">
                      {{$t('becomeManager.form.youAreAlreadyManager')}}
                    </h3>
                  </div>
                  <span class="final-step__desc">
                    ({{$t('becomeManager.form.noNeedGorYouToApply')}} <i class="smile-icon ml_5"/>)
                  </span>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="welcome-form-section__bg"></div>
    </div>

    <div class="show-intro-video-control" :style="{ 'top': (contentTopOffset + 70)  + 'px' }">
       <div @click="showIntro(true)" class="show-intro-video-control__img"
             v-tooltip.top="'Life at PeoplePolitics'"
        ></div>
    </div>

    <base-move-to-control :position-top="contentTopOffset + 90"/>

    <Dialog v-model:visible="showIntroState"
            class="intro-video-modal"
            modal
            :header="'Video instruction'"
    >
      <video id="introVideo" class="intro-video" width="800px" controls preload="auto" autoplay="true" tabindex="-1" role="application" src="https://d3lhb68qeo1nf6.cloudfront.net/video/people_politics_video_720.mp4" data-setup="{}">
        <source src="https://d3lhb68qeo1nf6.cloudfront.net/video/people_politics_video_720.mp4" type="video/mp4">
        <param name="allowfullscreen" value="true">
      </video>

      <template #footer>
        <button type="button" class="p-dialog-close-btn" @click="hideIntro()">
          {{$t('common.buttons.close')}}
        </button>
      </template>
    </Dialog>

  </div>
</template>

<style lang="scss">
.welcome-manager {
  top: 0;
  width: 100%;
  position: relative;

  .show-intro-video-control {
    position: fixed;
    right: 50px;
    top: 75px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 215px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 90px;
      margin-bottom: 10px;
      background: url("~@/assets/img/become-manager/bm-intro-video-tmb.png") no-repeat;
      background-size: 100%;

      &:after {
        content: "";
        display: block;
        height: 45px;
        width: 45px;
        background: url("~@/assets/img/proposal/video-play-btn-75.svg") center no-repeat;
        background-size: 100%;
      }
    }
  }

  .intro-video-modal {
    .base-modal {
      max-width: 800px;
      margin: 0 15px;
    }
    .intro-video {
      width: 100%;
      max-width: 800px;
      min-width: 800px;
    }

    @media (max-width: 880px) {
      .intro-video {
        max-width: 100%;
        min-width: 100%;
      }

      .base-modal {
        margin: 0 15px;
      }
    }
  }

  .welcome-header-wrap {
    width: 100%;
    background: #a163cd;

    .welcome-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //width: 1150px;
      max-width: 1150px;
      height: 100px;
      margin: 0 auto;
      padding: 0 20px;
      background: #a163cd;
      box-sizing: border-box;

      .header-logo {
        width: 213px;
        height: 48px;
        background: url("~@/assets/img/become-manager/logo-pp-for-managers-english@3x.png") no-repeat;
        background-size: 100%;
      }

      .header-nav {
        display: flex;
        align-items: center;

        .header-nav-item {
          margin-right: 44px;

          &__link {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.25;
            letter-spacing: -0.01px;
            color: rgba(255, 255, 255, 0.75);
            cursor: pointer;
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            color: #ffffff;
          }
        }
      }
    }
  }


  .welcome-section {
    width: 100%;
    position: relative;
    box-sizing: border-box;

    &__wrap {
      position: relative;
      margin: 0 auto;
      max-width: 1150px;
    }

    &__text-wrap {
      position: absolute;

      .title {
        margin: 0;
        font-size: 36px;
        line-height: 1.4;
        letter-spacing: -0.02px;
        text-transform: initial;
        color: #4f4f4f;

        &--main {
          font-size: 48px;
        }
        &--secondary {
          font-size: 24px;
        }

      }
    }

    &__first {
      height: 583px;
      padding: 0 20px;
      background: #a163cd;

      .welcome-section__text-wrap {
        top: 125px;
        max-width: 680px;
        .title {
          font-weight: 600;
          color: #FFFFFF;
        }

        .title--main {
          margin-bottom: 37px;
        }
      }

      .animation {
        position: absolute;
        right: 0;
        width: 100%;
        max-width: 520px;
      }
    }

    &__second {
      background: #ffffff;
      .welcome-section__text-wrap {
        top: 50px;
        right: 0;
        width: 580px;

        .title {
          margin-bottom: 24px;
          font-weight: 600;
        }
      }

      svg {
        position: relative;
        top: 6px;
      }
    }

    &__third {
      .welcome-section__text-wrap {
        top: 220px;
        z-index: 1;
        max-width: 485px;

        .title {
          margin-bottom: 25px;
          font-weight: 600;
          color: #ffffff;
        }
      }

      .animation {
        position: absolute;
        width: 590px;
        height: 590px;
      }
      svg {
        position: relative;
        top: 0;
      }
    }

    &__fourth {
      height: 915px;
      background: #FFFFFF;

      .welcome-section__text-wrap {
        width: 686px;
        bottom: 60px;
        left: 50%;
        margin-left: -343px;

        .title {
          margin-bottom: 24px;
          text-align: center;
          color: #4f4f4f;
        }
        .title--secondary {
          margin: 0;
        }
      }

      .animation {
        position: relative;
        top: -10px;
        height: 700px;
        background: #FFFFFF;
      }
    }
  }

  .welcome-footer {
    position: relative;
    //top: -14px;
    width: 100%;
    height: 622px;
    background: #a163cd;

    &__text-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 167px;

      .title {
        margin: 0;
        font-size: 36px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .welcome-footer-sections {
      display: flex;
      justify-content: center;
    }

    .welcome-footer-section {
      background: #a163cd;

      .welcome-footer-section__text {
        display: flex;
        width: 308px;
        max-width: 308px;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: -0.01px;
        color: #FFFFFF;
      }

      &--first {
        .welcome-footer-section__image {
          width: 240px;
          height: 259px;
          margin: 21px 115px 21px 0;
          background: url("~@/assets/img/become-manager/static-1@3x.png") no-repeat;
          background-size: 100%;
        }
        .welcome-footer-section__text {
          margin-right: 115px;
        }

      }
      &--second {
        .welcome-footer-section__image {
          width: 270px;
          height: 289px;
          margin-right: 75px;
          margin-bottom: 12px;
          background: url("~@/assets/img/become-manager/static-2@3x.png") no-repeat;
          background-size: 100%;
        }
        .welcome-footer-section__text {
          margin-right: 75px;
        }
      }
      &--third {
        .welcome-footer-section__image {
          width: 320px;
          height: 283px;
          margin: 2px 0 12px 0;
          background: url("~@/assets/img/become-manager/static-3@3x.png") no-repeat;
          background-size: 100%;
        }
        .welcome-footer-section__text {

        }
      }
    }
  }

  .welcome-form-section {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 40px;
    background: #7835a6;
    box-sizing: border-box;

    .title {
      display: inline-block;
      margin-bottom: 45px;
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
    }

    .welcome-form-wrap {
      width: 920px;
      min-height: 454px;
      position: relative;
      z-index: 1;
      margin: 0 auto;
      padding: 30px 0 0;
      border-radius: 37px;
      box-shadow: 0 3px 30px 0 rgba(79, 79, 79, 0.3);
      background: #E7E3E9;
      opacity: 0.95;

      .welcome-form {
        padding: 40px 75px;

        .login-box {
          padding: 20px 0;
          .login-box-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: 'Open Sans', Arial, sans-serif;
            color: #ffffff;
            border-radius: 2px;
            background: none;
            border: none;
            cursor: pointer;

            &--become {
              width: 165px;
              height: 36px;
              margin-right: 5px;
              font-size: 14px;
              background: #fe8f18;
              text-transform: uppercase;
              &:hover {
                box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35) inset;
              }
              &:active {
                box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.35) inset;
              }
            }
            &--login {
              height: 36px;
              max-width: 84px;
              background: transparent;
              &:hover {
                background: #672992;
              }
              &:active {
                background: #a163cc;
              }
            }
          }
        }

        .form-title {
          margin-bottom: 15px;
          color: #4f4f4f;
          font-size: 24px;
          font-weight: 700;
        }

        .form-info {
          display: inline-block;
          color: #3c3c3c;
        }

        .form-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 25px;

          .form-input {
            padding: 12px;
            border: solid 1px #d1d1d1;
            border-radius: 4px;
            font-size: 16px;
            font-family: "Open Sans", Arial, sans-serif;

            &::placeholder {
              color: #bbbbbb;
            }

            &:focus-visible {
              outline: none;
            }

            &:disabled {
              background: #d1d1d1;
              color: rgba(0,0,0,0.4);
            }

            &--textarea {
              min-height: 100px;
              resize: none;
            }

            &--error {
              border: 1px solid red !important;
            }
          }

          label {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 600;
            color: #4f4f4f;
          }

          input[type="text"],
          input[type="password"] {
            width: 360px;
            height: 40px;
            padding: 12px;
            border-radius: 4px;
            border: solid 1px #d1d1d1;
            background-color: #fff;
            font-size: 16px;
            box-sizing: border-box;
          }
        }

        .social-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          max-width: 375px;
          margin-bottom: 10px;

          &__icon {
            width: 30px;
            height: 30px;
            margin-right: 5px;
            background-size: 100%;
            background-repeat: no-repeat;

            &--fb {
              background: url("~@/assets/img/become-manager/social/f-logo-rgb-blue-58@3x.png") center/100% no-repeat;
            }
            &--linkedin {
              background: url("~@/assets/img/become-manager/social/li-in-bug@3x.png") center/100% no-repeat;
            }
            &--insta {
              background: url("~@/assets/img/become-manager/social/insta-icon.png") center/100% no-repeat;
            }
            &--snapchat {
              background: url("~@/assets/img/become-manager/social/snapchat-icon.png") center/100% no-repeat;
            }
          }
        }

        .bm-form-field {
          &::placeholder {
            color: #bbbbbb;
          }
          &:focus-visible {
            outline: none;
          }
          &.validationError {
            border-color: red !important;
          }
        }

        .validationMessage {
          margin-top: 5px;
          color: red;

          &--social-link {
            margin: -5px 0 0 35px;
          }
        }

        .submit-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 360px;
          height: 55px;
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: 600;
          color: #FFFFFF;
          border-radius: 8px;
          border: none;
          background: #7835a6;
          cursor: pointer;
        }

        .bankid-logo {
          width: 43px;
          height: 40px;
          margin-left: 8px;
          //background: url("../../html/images/manager-lounge/logo-1-default@3x.png");
          background-size: 100%;
        }

        .user-terms-link {
          font-size: 16px;
          text-align: center;
          color: #3c3c3c;

          a {
            font-weight: 600;
          }
        }
      }

      .wizard-step-list {
        display: flex;
        flex-wrap: wrap;
        transition: all .3s ease-in;
        .wizard-step {
          position: relative;
          font-size: 14px;
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
          text-align: center;
          transition: all .3s ease-in;

          &__label {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            color: #3c3c3c;
            .link {
              padding: 5px 0;
              color: #a163cd;
              cursor: pointer;
              font-size: 12px;
            }
          }

          &:before {
            content: "";
            position: absolute;
            top: -17px;
            left: 50%;
            width: 100%;
            height: 2px;
            z-index: 2;
            background: #a163cd;
            transition-property: all;
            transition-duration: .1s;
            transition-timing-function: ease-in;
            transition-delay: .1s;
          }

          &:after {
            content: "";
            position: absolute;
            top: -17px;
            left: 50%;
            width: 100%;
            height: 2px;
            background: #fff;
            z-index: 1;
          }

          &.active {
            &:before {
              background: #fff;
            }

            &:after {
              background: #fff;
            }

            &~.wizard-step {
              &:before {
                background: #dee2e6;
                width: 0;
              }
              .dot {
                background: #ffffff;
              }
            }

            .dot {
              background-color: #7835a6;
              box-shadow: 0 0 0 3px rgb(161, 99, 204);
            }
          }

          &.last {
            &:before,
            &:after {
              content: none;
            }
          }

          .dot {
            content: "";
            position: absolute;
            top: -22px;
            left: 50%;
            z-index: 3;
            height: 12px;
            width: 12px;
            background: #a163cd;
            border-radius: 50%;
            box-shadow: 0 0 0 2px #fff;
            transition: all .5s ease-in-out;
          }
        }

      }
    }

    .step-description {
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 600;
    }

    .step-wrap {
      padding-top: 70px;

      &--1 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 90px;

        .step-description {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 450px;
          line-height: 30px;
          &__item {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #4f4f4f;
          }
        }

        .step-description_info {
          font-size: 14px;
          font-weight: 400;
          color: #3c3c3c;
        }

        .step-description_link {
          font-size: 14px;
          font-weight: 600;
          color: #7835a6;
          cursor: pointer;
        }
      }
      &--2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 50px;

        .enter-bank-id-form {
          position: relative;

          label.error {
            position: absolute;
            color: #e60907;
            min-width: 200px;
            right: -270px;
            top: 12px;
          }
        }

        .bank-id-auth-form {
          max-width: 540px;
        }
      }
      &--3 {
        .form-title {
          &:first-child {
            margin-bottom: 30px !important;
          }
        }
        .form-inputs-wrap {
          display: flex;
          justify-content: space-between;
          margin-bottom: 40px;
        }

        .form-info {
          margin-bottom: 30px;
        }
      }
      &--4{
        .final-step {
          padding-bottom: 100px;
          .final-step-title {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 12px;
            &__icon {
              margin-right: 15px;
              font-size: 30px;
              color: #94ea6e
            }
            &__text {
              font-family: 'Open Sans', Arial, sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 23px;
              color: #4f4f4f;
              margin: 4px 0 20px;
            }
          }

          &__desc {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #3c3c3c;
          }
        }
      }

      .smile-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        margin-right: 3px;
        background: url("~@/assets/img/opinion/pretty_good.svg") no-repeat;
      }
    }

    &__bg {
      position: absolute;
      bottom: -76px;
      left: 0;
      width: 100%;
      height: 233px;
      background-color: #7835A6;
    }
  }
}

.intro-video-modal {
  .p-dialog-content {
    padding: 0;
  }
  .intro-video {
    width: 100%;
    max-width: 800px;
    min-width: 800px;
  }

  @media (max-width: 880px) {
    .intro-video {
      max-width: 100%;
      min-width: 100%;
    }

    .base-modal {
      margin: 0 15px;
    }
  }
}

@media (max-width: 1024px) {
  .welcome-manager {

    .welcome-section {
      &__first {
        height: auto;
        padding-bottom: 50px;

        .welcome-section__text-wrap {
          position: initial;
          margin-bottom: 50px;
        }

        .animation {
          position: initial;
          margin: 0 auto;
        }
      }
      &__second {
        .welcome-section__text-wrap {
          position: initial;
          padding: 20px;
          margin-bottom: 50px;
        }


      }
      &__third {
        .welcome-section__text-wrap {
          //position: initial;
          top: 25px;
          max-width: 320px;
          padding: 20px;
          margin-bottom: 50px;
        }

      }
      &__fourth {

      }
    }
    .welcome-footer {
      height: auto;
      padding-bottom: 50px;
      .welcome-footer-sections {
        flex-direction: column;
        align-items: center;

        .welcome-footer-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 75px;
          background: #a163cd;
        }

        .welcome-footer-section__text {
          width: auto;
          max-width: 450px;
        }

        .welcome-footer-section__image,
        .welcome-footer-section__text {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    .welcome-form-section {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 100px;

      .welcome-form-wrap {
        width: auto;

        .welcome-form {
          padding: 40px 35px !important;
        }
      }
    }
  }
}

</style>