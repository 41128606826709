<script>
import {mapActions} from "pinia";
import {baseLoaderStore} from "@/store/baseLoaderStore";

export default {
  name: "ManagerApplications",
  props: {
    userId: {
      type: Number
    },
    urlParams: {
      type: String
    }
  },
  computed: {
    frameUrl() {
      let url =  new URL(window.location.origin + `/${this.userId}/mydesktop?tab=applications`);

      if (this.urlParams) {
        let params = this.urlParams;
        params.append('tab', 'applications')
        url.search = params;
      }

      return url.pathname + url.search + '&_f=true';
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'hideBaseLoader'
    ]),
  },
  mounted() {
    document.getElementById('managerViewFrame').onload = () => {
      this.hideBaseLoader();
    };
  }
}
</script>

<template>
  <div>
    <iframe :src="frameUrl" frameborder="0" id="managerViewFrame" class="manager-view-frame"></iframe>
  </div>
</template>