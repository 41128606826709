<script>
import {mapState} from "pinia";
import {authStore} from "@/store/authStore";
import NotApprovedUserMessage from "@/components/HeaderMessage/NotApprovedUserMessage.vue";
import CookiePolicyMessage from "@/components/HeaderMessage/CookiePolicyMessage.vue";
import {maintenanceStore} from "@/store/maintenanceStore";
import ManagerSystemMaintenanceMessage from "@/components/HeaderMessage/ManagerSystemMaintenanceMessage.vue";

export default {
  name: "BaseHeaderMessage",
  components: {
    NotApprovedUserMessage,
    CookiePolicyMessage,
    ManagerSystemMaintenanceMessage
  },
  computed: {
    ...mapState(authStore, [
      'userActivated',
      'showCookiePolicyMessage'
    ]),
    ...mapState(maintenanceStore, [
      'isResolveContentReviewBlocked'
    ])
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    }
  }
}
</script>

<template>
  <div class="header-message" id="baseHeaderMessage">
    <not-approved-user-message v-if="!userActivated"/>
    <cookie-policy-message v-else-if="showCookiePolicyMessage"
                           @on-accept="onClose()"
    />
    <manager-system-maintenance-message v-if="isResolveContentReviewBlocked"
                                        @on-hide="onClose()"
    />
  </div>
</template>

<style lang="scss" scoped>
.header-message {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}
</style>