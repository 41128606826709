<script>
import i18n from "@/i18n";
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {maintenanceStore} from "@/store/maintenanceStore";

export default {
  name: "ManagerSystemMaintenanceMessage",
  computed: {
    ...mapState(maintenanceStore, [
      'resolveContentReviewInfo',
      'isResolveContentReviewBlocked'
    ]),
    getMessage() {
      if (this.isResolveContentReviewBlocked) {
        return `${i18n.global.t('header.message.managerSystemMaintenance.resolveContentReviewBlocked.title')} ${this.resolveContentReviewInfo.sinceDateTime} ${i18n.global.t('header.message.managerSystemMaintenance.resolveContentReviewBlocked.approximatelyUntil')} ${this.resolveContentReviewInfo.approximateUntilDateTime} ${i18n.global.t('header.message.managerSystemMaintenance.resolveContentReviewBlocked.apologizes')}`;
      }
      return "";
    }
  },
  methods: {
    ...mapActions(authStore, [
      'resendRegistrationEmail',
      'toggleChangeEmailModalState'
    ]),
    ...mapActions(maintenanceStore, [
        'hideMaintenanceMessage'
    ]),

    hideMessage() {
      this.hideMaintenanceMessage();
      this.$emit('on-hide');
    }
  }
}
</script>

<template>
  <div class="system-maintenance-message">
    <div class="system-maintenance-message__content">
      <span class="system-maintenance-message__info">
        {{getMessage}}
      </span>
    </div>
    <font-awesome-icon :icon="['fas', 'xmark']"
                       @click="hideMessage()"
                       class="system-maintenance-message__icon"
    />
  </div>
</template>

<style lang="scss" scoped>
.system-maintenance-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  background: #fe8f18;
  &__content {
    display: flex;
    justify-content: space-between;
    width: 970px;
  }
  &__info {
    font-weight: 700;
    color: #ffffff;
  }
  &__icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
  }
}
</style>