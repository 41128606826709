import {defineStore} from "pinia";
import axios from "axios";
import {UserTaskMaintenance, UserTaskMaintenanceType} from "@/store/model/UserTaskMaintenance";

export const maintenanceStore = defineStore(
    "maintenanceStore", {
        state: () => ({resolveContentReviewInfo: UserTaskMaintenance}),
        getters: {
            isResolveContentReviewBlocked() {
                return this.resolveContentReviewInfo && this.resolveContentReviewInfo.locked;
            }
        },
        actions: {
            getResolveContentReviewInfo() {
                axios.get("/api/maintenance/user/tasks/locks?type=" + UserTaskMaintenanceType.RESOLVE_CONTENT_REVIEW).then((resp) => {
                    this.resolveContentReviewInfo = new UserTaskMaintenance(resp.data);
                }).catch(error => {
                    console.error(error);
                    this.initEmptyResolveContentReviewInfo();
                });
            },
            initEmptyResolveContentReviewInfo() {
                this.resolveContentReviewInfo = new UserTaskMaintenance({
                    type: UserTaskMaintenanceType.RESOLVE_CONTENT_REVIEW,
                    locked: false
                });
            },
            hideMaintenanceMessage() {
                this.resolveContentReviewInfo =  new UserTaskMaintenance({
                    type: '',
                    locked: false,
                    sinceDateTime: '',
                    approximateUntilDateTime: ''
                });
            }
        }
});