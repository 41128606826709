<script>
import {useVuelidate} from "@vuelidate/core";
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {reportStore} from "@/store/reportStore";
import {authStore} from "@/store/authStore";
import ProposalActionOpinionControl from "@/views/proposal/proposal-action-bar/ProposalActionOpinionControl.vue";
import ProposalActionArgumentRatedControls
  from "@/views/proposal/proposal-action-bar/ProposalActionArgumentRatedControls.vue";
import AddArgumentsModal from "@/views/opinion/opinion-modals/AddArgumentsModal.vue";
import AlertOctagon from 'vue-material-design-icons/AlertOctagon.vue';
import Send from 'vue-material-design-icons/Send.vue';
import {bankIdStore} from "@/store/bankIdStore";
import {argumentStore} from "@/store/argumentStore";
import {documentStore} from "@/store/documentStore";

export default {
  name: "ProposalActionBar",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    ProposalActionOpinionControl,
    ProposalActionArgumentRatedControls,
    AddArgumentsModal,
    AlertOctagon,
    Send
  },
  props: {
    disabledState: {
      type: Boolean
    },
    activeUserType: {
      type: String,
      default: 'USER'
    }
  },
  data() {
    return {
      importanceSettingsTitle: '',
      opinionData: {
        opinionLike: null,
        opinionPrivacy: 'PUBLIC'
      }
    }
  },
  computed: {
    ...mapState(authStore, ['activeUserInfo']),
    ...mapState(proposalStore, [
      'proposalInfo',
      'editProposalViewState',
      'editedProposalData',
      'createPropFormViewState',
      'selectedActualType',
      'actualProposalTextVal',
      'proposalArguments',
      'editProposalHasChanges',
    ]),
    barModifierClass() {
      return this.activeUserType === 'ORGANIZATION'
          ? 'action-bar-wrap--org-mode'
          : '';
    },
    isLatestProposalVersion() {
      return this.proposalInfo.version === this.proposalInfo.versions.length;
    },
    canEditProposal() {
      let state = false;

      if (this.activeUserInfo) {
        const userId = this.activeUserInfo.id;
        const proposerId = this.proposalInfo.proposer.id;
        state = userId === proposerId;
      }

      return state;
    },
    noChangedDataText() {
      return this.editProposalHasChanges ? null : "You need to make a change to the proposal in order to save";
    },
    isSaveAndCloseDisabled() {
      return this.noChangedDataText !== null;
    }
  },
  methods: {
    ...mapActions(authStore,[
       'checkUserIsApproved'
    ]),
    ...mapActions(bankIdStore, [
      'checkBankIdAuthSession'
    ]),
    ...mapActions(proposalStore, [
      'toggleEditProposalState',
      'closeEditProposalView',
      'closeCreatePropFormView',
      'createProposalAction',
      'publishProposalAction',
      'setSavePropValue',
      'setUpdateActualProposalAction',
      'setProposalArguments',
      'setUpdateTextActualProposal',
      'setUpdatePdfActualProposal',
      'processProposalSaving'
    ]),
    ...mapActions(argumentStore, [
      'createNewArgument',
      'updateArgument',
      'setArgumentContent',
      'getArguments'
    ]),
    ...mapActions(documentStore, [
      'createTextDocument',
      'updateTextDocument',
    ]),
    ...mapActions(reportStore, [
      'toggleReportModal'
    ]),
    openLatestVersion() {
      this.$router.push({ name: 'ProposalView', params: { id: this.proposalInfo.versions[0].id } });
    },
    report() {
      if (this.checkUserIsApproved()) {
        this.toggleReportModal();
      }
    },
    openEditProposalView() {
      this.checkBankIdAuthSession(()=> {
        this.toggleEditProposalState();
      })
    },
    saveProposal(actionType) {
      if (actionType === "DRAFT" && this.editProposalHasChanges) {
        this.$emit('on-save-proposal', actionType);
      }

      if (actionType === "PUBLISH") {
        this.$emit('on-save-proposal', actionType);
      }
    },
  },
}
</script>

<template>
  <div class="action-bar-wrap"
       :class="barModifierClass"
  >
    <template v-if="editProposalViewState || createPropFormViewState">
      <div class="edit-proposal-controls">
        <span class="close-edit-btn"
              v-tooltip.top="{ value: noChangedDataText, class: 'no-changed-data-tooltip' }"
              :class="{'close-edit-btn--disabled': isSaveAndCloseDisabled}"
              @click="saveProposal('DRAFT')"
        >
          {{$t('proposalActionBar.saveAndClose')}}
        </span>
        <button class="publish-proposal-btn"
                @click="saveProposal('PUBLISH')"
        >
          <span class="publish-proposal-btn__text">
             {{$t('proposalActionBar.publish')}}
          </span>
          <send class="publish-proposal-btn__icon"/>
        </button>
      </div>
    </template>
    <template v-else>
      <div class="action-bar">
        <template v-if="canEditProposal">
          <!-- Edit proposal controls -->
          <div class="edit-proposal-buttons">
            <template v-if="!isLatestProposalVersion">
              <div class="go-to-proposal-btn" @click="openLatestVersion">
                <i class="go-to-proposal-btn__icon"></i>
                <span class="go-to-proposal-btn__text">
                  {{$t('proposalActionBar.goToLatest.text')}}
                </span>
                <div class="proposal-control-tooltip">
                  {{$t('proposalActionBar.goToLatest.tooltip')}}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="edit-proposal-btn" @click="openEditProposalView">
                <i class="edit-proposal-btn__icon"></i>
                <span class="edit-proposal-btn__text">
                    {{$t('proposalActionBar.editYourProposal.text')}}
                </span>
                <div class="proposal-control-tooltip">
                  {{$t('proposalActionBar.editYourProposal.tooltip')}}
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="enter-opinion-btn-wrap">
            <proposal-action-opinion-control
                :disabled-state="disabledState"
            />
          </div>
        </template>
        <div class="additional-actions">
          <proposal-action-argument-rated-controls
              :disabled-state="disabledState"
          />
          <div class="report-control" @click="report">
            <alert-Octagon class="addition-link__icon"/>
            <i class="report-control__icon"></i>
            <span class="report-control__label">
              {{$t('report.report')}}
            </span>
          </div>
        </div>
      </div>
      <add-arguments-modal v-if="proposalInfo"
                           :proposal-id="proposalInfo.id"
                           :opinion-like="opinionData.opinionLike"
      ></add-arguments-modal>
    </template>
  </div>
</template>

<style lang="scss">
.action-bar-wrap {
  width: 1200px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  margin-left: -600px;
  bottom: 8px;
  z-index: 1;
  background: #fff;
  border-radius: 35px;
  box-shadow: 0 4px 24px 4px rgba(161, 99, 205, .3);
  .action-bar {
    display: flex;
    align-items: center;
    width: 970px;
    height: 70px;
    background: #fff;
    box-sizing: border-box;
  }

  .edit-proposal-buttons {
    margin-right: 45px;

    .edit-proposal-btn,
    .go-to-proposal-btn {
      display: flex !important;
      align-items: center;
      min-width: 225px;
      height: 42px;
      padding: 0 7px;
      color: #7835a6;
      &__icon {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        background: url("~@/assets/img/proposal/proposer.svg"), no-repeat, center;
      }
      &__text {
        font-size: 16px;
        font-weight: 600;
      }
      &:hover {
        background: #e6dbe9;
        cursor: pointer;

        .proposal-control-tooltip {
          display: block;
        }
      }
    }

    .proposal-control-tooltip {
      position: absolute;
      height: 30px;
      line-height: 29px;
      white-space: nowrap;
      padding: 0 15px;
      text-align: center;
      color: #7835a6;
      background: #ffffff;
      left: 120px;
      top: -25px;
      display: none;
      font-size: 12px;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
      box-shadow: 0 0 2px #e1e1e1;

      &:before {
        content: "";
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #e1e1e1;
        left: 13px;
        top: 29px;
        z-index: 20;
      }

      &:after {
        content: "";
        position: absolute;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 11px solid #ffffff;
        left: 14px;
        top: 27px;
        z-index: 20;
      }
    }
  }

  .edit-proposal-controls {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 970px;
    .publish-proposal-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 127px;
      height: 35px;
      margin-left: 25px;
      background: #7835a6;
      border-radius: 2px;
      border: none;
      cursor: pointer;

      &__text {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: 'Open Sans', Arial, sans-serif;
        line-height: 14px;
        color: #FFFFFF;
      }
      &__icon {
        display: flex;
        margin-left: 10px;
        font-size: 14px;
        color: #fff;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    .close-edit-btn {
      margin-top: -15px;
      padding-top: 15px;
      font-size: 14px;
      font-weight: 600;
      font-family: 'Open Sans', Arial, sans-serif;
      text-transform: uppercase;
      color: #7835a6;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &--disabled {
        cursor: default;
        color: #4f4f4f61;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .enter-opinion-btn-wrap {
    min-width: 215px;
    margin-right: 40px;
  }

  &--org-mode{
    border: solid 2px #a163cd;
    background: #f7ecff;

    .action-bar {
      background: #f7ecff;
    }
  }
}

.additional-actions {
  display: flex;
  .report-control {
    display: flex;
    align-items: center;
    height: 42px;
    padding: 0 7px;
    margin-left: 46px;
    color: #7835a6;
    border-radius: 4px;
    background: 0 0;
    border: none;
    &__icon {
      margin-right: 5px;
    }
    &__label {
      font-size: 16px;
      font-weight: 600;
    }
    &:hover {
      background: #e6dbe9;
      cursor: pointer;
    }
  }
}

.no-changed-data-tooltip {
  min-width: fit-content;
}
</style>