<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {proposalStore} from "@/store/proposalStore";
import {bankIdStore} from "@/store/bankIdStore";
import {opinionStore} from "@/store/opinionStore";
import {argumentStore} from "@/store/argumentStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import BaseHeader from "@/components/BaseHeader.vue";
import ProposalWarnMessage from "@/views/proposal/ProposalWarnMessage.vue";
import ProposalGeneralInfo from "@/views/proposal/proposal-general-info/ProposalGeneralInfo.vue";
import ProposalOpinionSection from "@/views/proposal/ProposalOpinionSection.vue";
import ProposalArgument from "@/views/proposal/proposal-argument/ProposalArgument.vue";
import ProposalNoArguments from "@/views/proposal/ProposalNoArguments.vue";
import ProposalCounterSection from "@/views/proposal/ProposalCounterSection.vue";
import ProposalArgumentsList from "@/views/proposal/ProposalArgumentsList.vue";
import ProposalOpinionsOfOthers from "@/views/proposal/ProposalOpinionsOfOthers.vue";
import BaseSidebar from "@/components/BaseSidebar.vue";
import ProposalActionBar from "@/views/proposal/proposal-action-bar/ProposalActionBar.vue";
import ProposalNotAvailable from "@/views/proposal/ProposalNotAvailable.vue";
import ProposalLifeCircleModal from "@/views/proposal/proposal-modals/ProposalLifeCircleModal.vue";
import ProposersListModal from "@/components/Modals/ProposersListModal.vue";
import ActualProposalModal from "@/components/Modals/ActualProposalModal.vue";
import EditProposalView from "@/views/proposal/proposal-edit/EditProposalView.vue";
import ProposalSavedDraftModal from "@/views/proposal/proposal-modals/ProposalSavedDraftModal.vue";

export default {
  name: "ProposalView",
  components: {
    BaseHeader,
    ProposalWarnMessage,
    ProposalGeneralInfo,
    ProposalOpinionSection,
    ProposalArgument,
    ProposalNoArguments,
    ProposalCounterSection,
    ProposalArgumentsList,
    ProposalOpinionsOfOthers,
    BaseSidebar,
    ProposalActionBar,
    ProposalNotAvailable,
    ProposalLifeCircleModal,
    ProposersListModal,
    ActualProposalModal,
    EditProposalView,
    ProposalSavedDraftModal
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState(authStore, [
      'activeUserInfo',
      'hasHeaderMessage'
    ]),
    ...mapState(proposalStore, [
      'proposalInfo',
      'editedProposalData',
      'editProposalViewState',
      'proposalNotAvailable',
      'actualProposalModalState',
      'proposalArguments',
      'savedDraftModalState'
    ]),
    ...mapState(opinionStore, [
        'currentOpinion'
    ]),
    isOpinionView() {
      return this.$route.name === "OpinionView";
    },
    proposalId() {
      let proposalId = '';
      if (this.$route.name === 'opinionpage') {
        proposalId = this.opinionObject.proposal ? this.opinionObject.proposal.id : '';
      } else {
        proposalId = !this.$route.params.id.includes('pp') ? this.$route.params.id : '';
      }

      return proposalId;
    },
    proposalIssueNumber() {
      return this.$route.params.id.includes('pp') ? this.$route.params.id : '';
    },
    isLatestProposalVersion() {
      return this.proposalInfo.status === 'CURRENT' || this.proposalInfo.status === 'DRAFT';
    },
    argumentsWithContent() {
      return this.proposalArguments.filter((arg)=> !!arg.argumentDetailed)
    },
    proposalArgumentsList() {
      return this.proposalArguments.filter((arg)=> !!arg.argumentDetailed || arg.privateSinceDateTime)
    },
    counterProposal() {
      const externalProposalType = this.proposalInfo.extraAttributes.externalProposalType;
      return externalProposalType === 'COUNTER_MOTION';
    },
    reviewedProposal() {
       const contentStatus = this.proposalInfo.extraAttributes.contentStatus;
       return contentStatus === null || contentStatus === 'PROCESSED';
    },
    opinionDisabledState() {
      // return this.counterProposal;
      return true; //TODO enable in "Support for Opinions" release
    },
    activeUserType() {
      let type = ''

      if (this.activeUserInfo) {
        if (this.activeUserInfo.organization) {
          type = "ORGANIZATION"
        } else {
          type = `USER`;
        }
      }

      return type;
    },
    hasActualProposal() {
      return this.proposalInfo.actualProposal;
    },
    contentClass() {
      let contentClass = '';

      if (this.hasHeaderMessage) {
        contentClass += 'content-section--has-message '
      }

      return contentClass;
    },
  },
  watch: {
    editProposalViewState(val) {
      if (!val) {
        this.getArguments(this.proposalInfo.id).then((resp) => {
          this.setProposalArguments(resp.data.content);
        })
      }
    }
  },
  methods: {
    ...mapActions(bankIdStore, ['checkBankIdAuthSession']),
    ...mapActions(baseLoaderStore, ['hideBaseLoader']),
    ...mapActions(proposalStore, [
      'getProposalInfo',
      'getProposalInfoByIssueNumber',
      'closeEditProposalView',
      'setSavePropValue',
      'setProposalArguments',
      'toggleEditProposalState',
      'processProposalSaving'
    ]),
    ...mapActions(opinionStore, [
      'getOpinion',
    ]),
    ...mapActions(argumentStore, [
      'getArguments',
      'setActiveArgument'
    ]),
    saveProposal(actionType) {
      this.$refs.editProposalView.saveProposal(actionType);
    },
   afterGetProposalActions() {
      this.getArguments(this.proposalInfo.id).then((resp) => {
        this.setProposalArguments(resp.data.content);
        if (this.proposalArguments.length > 0) {
          const idx = this.proposalArguments.findIndex(a => !!a.argumentDetailed);
          if (idx >= 0) {
            this.setActiveArgument(this.proposalArguments[idx], idx);
          }
        }
        this.hideBaseLoader();
      })
    }
  },
  beforeUnmount() {
    this.closeEditProposalView();
  },
  mounted() {
    if (this.isOpinionView) {
      this.getOpinion();
    } else {
      if (this.$route.query && this.$route.query.editMode) {
        this.checkBankIdAuthSession(()=> {
          this.toggleEditProposalState();
        });
      }
    }

    if (this.proposalIssueNumber) {
      const proposalVersion = this.$route.params.version;
      this.getProposalInfoByIssueNumber(this.proposalIssueNumber, proposalVersion).then(()=> {
        this.afterGetProposalActions();
      })
    } else {
      this.getProposalInfo(this.proposalId).then(()=>{
        this.afterGetProposalActions();
      });
    }
  }
}
</script>

<template>
  <base-header/>
  <template v-if="!proposalNotAvailable">
    <div v-if="proposalInfo"
         class="content-section"
         :class="contentClass"
    >
      <div class="content-section__wrap">
        <template v-if="editProposalViewState">
          <edit-proposal-view ref="editProposalView"/>
        </template>
        <template v-else>
          <proposal-warn-message v-if="!isLatestProposalVersion"/>
          <proposal-general-info
              :proposal-info="proposalInfo"
          />
          <proposal-opinion-section v-if="isOpinionView"/>
          <template v-if="!counterProposal">
            <proposal-argument v-if="argumentsWithContent.length > 0"
                               :arg-id="proposalInfo.argId"
                               :proposal-id="proposalInfo.id"
            />
            <proposal-no-arguments v-else/>
          </template>

          <proposal-arguments-list v-if="proposalArgumentsList.length > 0"/>

          <div style="display: flex; justify-content: space-between;">
            <template v-if="counterProposal">
              <proposal-counter-section
                  :reviewed-proposal="reviewedProposal"
                  :proposal-info="{
                  proposalTitle: proposalInfo.title,
                  issueNumber:  proposalInfo.issueNumber
                }"
              />
            </template>
            <template v-else>
              <proposal-opinions-of-others/>
            </template>
            <base-sidebar/>
          </div>
        </template>
      </div>
      <proposal-action-bar :disabled-state="opinionDisabledState"
                           :active-user-type="activeUserType"
                           @on-save-proposal="saveProposal"
      />

      <!-- Modals-->
      <proposal-life-circle-modal :proposal-id="proposalId"/>
      <proposers-list-modal :proposal-title="proposalInfo.title"
                            :proposers-list="proposalInfo.extraAttributes.spokes"
      />
      <actual-proposal-modal v-if="hasActualProposal && actualProposalModalState"
                             :actual-proposal="proposalInfo.actualProposal"
      />
      <proposal-saved-draft-modal v-if="savedDraftModalState"/>
    </div>
  </template>
  <proposal-not-available v-else/>
</template>

<style lang="scss" scoped>
.content-section {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  background: #E9EAED;

  .content-section__wrap {
    max-width: 980px;
    margin-bottom: 100px;
  }

  &--has-message {
    padding-top: 125px;
  }
}
</style>