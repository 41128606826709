<script>
import {mapActions} from "pinia";
import {baseLoaderStore} from "@/store/baseLoaderStore";
export default {
  name: "ManagerAds",
  props: {
    userId: {
      type: Number
    }
  },
  computed: {
    frameUrl() {
      return `/${this.userId}/mydesktop?tab=ads&_f=true`
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'hideBaseLoader'
    ]),
  },
  mounted() {
    document.getElementById('managerViewFrame').onload = () => {
      this.hideBaseLoader();
    };
  }
}
</script>

<template>
  <div>
    <iframe :src="frameUrl" frameborder="0" id="managerViewFrame" class="manager-view-frame"></iframe>
  </div>
</template>